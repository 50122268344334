<template>
    <div class="list-view">
        <page-title>
            <el-button @click="addRow" type="primary" icon="el-icon-plus"  class="filter-item">
                新增
            </el-button>
        </page-title>
        

        <pageable :url="url" :query="query" >
            <template v-slot:table >
                <!-- <el-table-column align="center" type="selection" width="50"> </el-table-column> -->
                <el-table-column prop="id" label="ID" width="100"> </el-table-column>
                <el-table-column prop="username" label="用户名"> </el-table-column>
                <el-table-column prop="nickname" label="昵称"> </el-table-column>
                <el-table-column label="头像">
                    <template v-slot="{ row }">
                        <el-image
                            style="width: 30px; height: 30px"
                            :src="row.avatar"
                            fit="cover"
                            :preview-src-list="[row.avatar]"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="手机" prop="phone"></el-table-column>
                <el-table-column label="角色" min-width="120">
                    <template v-slot="{row}">
                        <el-tag v-for="item in row.authorities" style="margin-right:10px;">{{item.description}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createdAt" width="150"></el-table-column>
                <el-table-column label="操作" align="center" fixed="right">
                    <template v-slot="{ row }">
                        <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    </template>
                </el-table-column>
            </template>
        </pageable>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name:"AdminList",
    data() {
        return {
            url: '/user/all',
            query:{
                admin: true
            }
        };
    },
    computed: {
        ...mapState([]),
    },
    methods: {
        addRow() {
            this.$router.push({
                path: '/adminEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/adminEdit',
                query: {
                    id: row.id
                }
            });
        },
       
    }
};
</script>

<style lang="less" scoped>

</style>
