<template>
    <div class="list-view">
        <page-title>
            <el-button @click="showEditDialog()" type="primary" icon="el-icon-plus"  class="filter-item">
                新增
            </el-button>
        </page-title>
        

        <pageable :url="url" :query="query"  ref="pageable">
            <template v-slot:table >
                <el-table-column prop="id" label="ID" min-width="80px"> </el-table-column>
                <el-table-column prop="userId" label="用户ID"> </el-table-column>
                <el-table-column prop="type" label="类型">
                   <template v-slot="{row}">
                        <el-tag v-for="item in emailTypes" v-if="item.value == row.type" :type="item.type" >
                            {{item.name}}
                        </el-tag>
                   </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" min-width="220px" > </el-table-column>
                <el-table-column prop="readEmail" label="是否已读">
                    <template v-slot="{row}">
                        <el-tag type="primary" v-if="row.readEmail">已读</el-tag>
                        <el-tag type="info" v-else >未读</el-tag>
                    </template>    
                </el-table-column>
                <el-table-column prop="createdAt" label="创建时间"  min-width="220px" sortable="custom"> </el-table-column>
                <el-table-column prop="expirationTime" label="过期时间" min-width="220px"  sortable="custom"> </el-table-column>
                <el-table-column label="操作">
                   <template v-slot="{row}">
                     <el-button type="text" @click="showEditDialog(row)">编辑</el-button>
                    </template>
                </el-table-column>
            </template>
        </pageable>




        <!-- 发送 -->
        <el-dialog :visible.sync="editDialog" 
         width="680px" 
        :title="editTitle" 
        :close-on-click-modal="false"
        
        >
            <el-form :model="editForm"  ref="editForm" :rules="editRules" label-width="100px" v-loading="saving" > 
                <el-form-item prop="sendType" label="发送方式">
                    <el-radio-group v-model="editForm.sendType"  :disabled="sendTypeDisable">
                        <el-radio label="0"  border>
                            指定用户
                        </el-radio>
                        <el-radio label="1" border>
                            所有用户
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="userId" label="用户"  v-if="editForm.sendType == '0' ">
                    <user-search v-model="editForm.userId"></user-search>
                </el-form-item>
                <el-form-item prop="type" label="类型">
                    <el-radio-group v-model="editForm.type">
                        <el-radio   v-for="item in emailTypes" :label="item.value" border="" >{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="title" label="标题">
                    <el-input type="text" v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item prop="content" label="内容">
                        <el-input type="textarea" v-model="editForm.content" :rows="5"></el-input>
                </el-form-item>
                <el-form-item prop="extend" label="赠送道具配置" v-if="editForm.type == 1 ">
                        <!-- <el-input type="textarea" v-model="editForm.extend" :rows="5"></el-input> -->
                        <el-checkbox-group v-model="propChecked"  >
                            <div class="prop-list" v-for="item in propsList"> 
                                <el-checkbox  :label="item.value" >
                                    <span class="prop-tag">{{ item.name }}</span>
                                </el-checkbox>
                                <el-input-number v-model="item.num" :min="1" placeholder="数量"  :disabled="!item.checked"></el-input-number>
                            </div>
                           
                        </el-checkbox-group>
                </el-form-item>
                <el-form-item prop="expirationTime" label="有效期">
                        <el-date-picker
                            v-model="editForm.expirationTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择有效期">
                        </el-date-picker>
                </el-form-item>
                
                
                <el-form-item label="多语言">
                    <el-tabs v-model="langActive" >
                        <el-tab-pane v-for="(item, index) in emailLangs" :label="item.description" :name="item.value">
                            <el-form-item label="标题">
                                <el-input v-model="emailContents[index].title" placeholder="请输入标题"></el-input>
                            </el-form-item>
                            <el-form-item label="内容">
                                <el-input type="textarea" v-model="emailContents[index].content" :rows="5"  placeholder="请输入内容">
                                </el-input>
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </el-form-item>

            </el-form>

            <div slot="footer">
                <el-button type="primary" size="mini" @click="sendEmail" :loading="saving">{{opName}}</el-button>
                <el-button size="mini" @click="editDialog = false">取消</el-button>
            </div>
        </el-dialog>



    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name:"GameEmail",
    data() {
        return {
            url: '/email/all',
            query:{
                
            },
            opName:"发送",
            saving: false,
            editTitle:"",
            editDialog: false,
            sendTypeDisable: false,
            editForm: {
                sendType: "0",
            },
            editRules:{
                sendType:[{required:true, message:"不能为空", trigger:"blur"}],
                userId:[{required:true, message:"不能为空", trigger:"blur"}],
                type:[{required:true, message:"不能为空", trigger:"blur"}],
                title:[{required:true, message:"不能为空", trigger:"blur"}],
                content:[{required:true, message:"不能为空", trigger:"blur"}],

            },
            emailTypes:[
                {
                    name:"普通邮件",
                    value:0,
                    type:"info",
                    checked: false,
                },
                {
                    name:"道具邮件",
                    value:1,
                    type:"primary",
                    checked: false,
                }
            ],
            //格式：diamond_50;coin_100
            propChecked:[],
            propsList: [
                {
                    name:"钻石",
                    value:"diamond",
                    num: 0,
                },
                {
                    name:"金币",
                    value:"coin",
                    num: 0,
                }
            ],
            emailLangs:[],
            langActive: "",
            emailContents: [],
        };
    },
    computed: {
        ...mapState([]),
    },
    mounted(){
        this.initLangs();
    },
    methods: {
       
        async initLangs(){
            try{
                let langs = await this.$http.get("/email/getEmailLangs");
                this.emailLangs = langs;
                if(this.emailLangs && this.emailLangs.length > 0){
                    this.langActive = this.emailLangs[0].value;
                }

                this.emailLangs.forEach((item) => {
                    this.emailContents.push({
                        emailLang: item.value,
                        title:null,
                        content:null,
                    });
                })
            }catch(e){
                this.$message.error("邮件语言加载失败:" + e.error);
            }

            
        },
        clearEmailContents(){
            this.emailContents.forEach(item => {
                    item.id = null;
                    item.emailId = null;
                    item.title = null;
                    item.content = null;
                });
        },
        clearExtend(){
            this.propChecked = [];
            this.propsList.forEach(item => {
                item.num = 1;
                item.checked = false;
            })
        },
        loadEmailContents(){
            //设置多语言内容
            if(this.editForm.emailContents){
                this.emailContents.forEach(item => {
                    let findObj = null;
                    for(let i = 0; i < this.editForm.emailContents.length; i++){
                        let obj = this.editForm.emailContents[i];
                        if(obj.emailLang == item.emailLang){
                            findObj = obj;
                            break;
                        }
                    }

                    if(findObj){
                        for(let key in findObj){
                            item[key] = findObj[key];
                            item.checked = true;
                        }
                    }
                });
            }
        },

        loadExtend(){
            if(this.editForm.extend){
                let ary = this.editForm.extend.split(";");
                ary.forEach(itemStr => {
                    let itemAry = itemStr.split("_");
                    let oldObj = {
                        value: itemAry[0],
                        num: itemAry[1]
                    };

                    let findObj = null;
                    for(let i = 0 ; i < this.propsList.length; i++){
                        let obj = this.propsList[i];
                        if(obj.value == oldObj.value){
                            findObj = obj;
                            break;
                        }
                    }

                    if(findObj == null){
                        console.warn("逻辑错误，道具信息未找到：" + oldObj.value);
                        return;
                    }

                    findObj.num = oldObj.num;
                    this.propChecked.push(findObj.value);

                })
            }
        },

        getExpendStr(){
            //生成道具字符串
            let ary = [];
            if(this.propChecked){
                
                this.propChecked.forEach(item => {
                    let findObj = null;
                    for(let i = 0 ; i < this.propsList.length; i++){
                        let obj = this.propsList[i];
                        if(obj.value == item){
                            findObj = obj;
                            break;
                        }
                    }
                    if(findObj == null){
                        throw new Error("逻辑错误，道具信息未找到：" + item);
                    }
                    ary.push(findObj.value + "_" + findObj.num);
                });

            }

            return ary.join(";");
        },
        showEditDialog(row){
            this.editForm = {};
            this.clearEmailContents();
            this.clearExtend();
            if(row){
                this.editForm = {...row};
                if(this.editForm.userId){
                    this.editForm.sendType = "0";
                }else{
                    this.editForm.sendType = "1";
                }
                this.loadEmailContents();
                this.loadExtend();
                this.editTitle = "编辑邮件";
                this.opName = "修改";
                this.sendTypeDisable = true;
                
            }else{
                this.editTitle = "新增邮件";
                this.opName = "发送";
                this.sendTypeDisable = false;
                
            }


            this.editDialog = true;
            
       },
       sendEmail(){
            let reqData = {
                ...this.editForm,
                emailContents: this.emailContents
            };

            if(reqData.type == 1){
                //道具类型
                reqData.extend = this.getExpendStr();
            }
            
            this.$refs.editForm.validate((flag) => {
                if(flag){
                    if(this.editForm.sendType == "1"){
                        //所有人需要提示一下
                        this.$confirm("确定发送给所有用户？", "提示信息").then(() => {
                            this.saving = true;
                            this.$http.post("/email/addAllEmail", reqData, {body:"json"}).then((res) => {
                                this.$message.success("操作成功");
                                this.editDialog = false;
                                this.$refs.pageable.loadData();
                            }).catch(e => {
                                let msg = e.message || e.error;
                                this.$message.error(msg);
                            }).finally(() => {
                                this.saving = false;
                            });
                        })
                    }else{
                        this.saving = true;
                        this.$http.post("/email/addOrUpdateEmail", reqData, {body:"json"}).then((res) => {
                            this.$message.success("操作成功");
                            this.editDialog = false;
                            this.$refs.pageable.loadData();
                        }).catch(e => {
                            let msg = e.message || e.error;
                            this.$message.error(msg);
                        }).finally(() => {
                            this.saving = false;
                        });
                    }
                    
                }
            })
        


        }

       
    },
    watch:{
        propChecked(value, oldValue){
            
            this.propsList.forEach(item => {
                let isFind = false;
                if(value){
                    for(let i = 0; i < value.length; i++){
                        let prop = value[i];
                        if(prop == item.value){
                            isFind = true;
                            break;
                        }
                    }
                }

                if(isFind){
                    item.checked = true;
                }else{
                    item.checked = false;
                }
                
                
            })
        }
    }
};
</script>

<style lang="less" scoped>

    .prop-list{
        margin-bottom: 10px;

    }

    .prop-tag{
        padding-right: 20px;
    }
</style>
