<template>


    <div class="list-view">

        <page-title>
            
        </page-title>

        <pageable
         url="/userGameData/all" 
         :query="query"
         downloadUrl="/userGameData/excel"
         deleteUrl="/userGameData/del"
         editRef="editable"
         >
            <template v-slot:condition >
                <el-form :model="query" :inline="true" >
                    <el-form-item label="用户ID">
                        <el-input-number v-model="query.userId" placeholder="请输入用户ID" ></el-input-number>
                    </el-form-item>

                    <el-form-item label="openid">
                        <el-input v-model="query.openid" placeholder="请输入openid" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="游戏ID">
                        <el-input v-model="query.gameId" placeholder="请输入游戏ID" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="版本号">
                        <el-input-number v-model="query.version" placeholder="请输入版本号" clearable></el-input-number>
                    </el-form-item>

                    <el-form-item label="创建时间">
                        <date-range-picker v-model="query.createdAt"  name="创建"></date-range-picker>
                    </el-form-item>
                </el-form>
            </template>

           <template v-slot:table >
                
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="userId" label="用户ID"> </el-table-column>
                <el-table-column prop="openid" label="openid"> </el-table-column>
                <el-table-column prop="gameId" label="游戏ID"> </el-table-column>
                <el-table-column prop="gameData" label="游戏数据"  min-width="120px" show-overflow-tooltip> 
                    <template v-slot="{row}">
                        <el-button type="primary" size="mini" plain @click="viewHandle(row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="version" label="版本号" > </el-table-column>
                <el-table-column prop="createdAt" label="创建时间" min-width="220px"   > </el-table-column>
                
           </template>
        </pageable>
        
        <editable ref="editable" url="/userGameData/update" :formItems="formItems">

        </editable>

        <el-dialog  :title="viewTitle" :visible.sync="viewVisible">
            <json-view :json="viewData"></json-view>
        </el-dialog>


    </div>
</template>
<script>


export default {
    name: 'GameDataList',
    data() {
        return {
            query :{
                userId: undefined,
                openid:null,
                gameId:null,
                version:undefined,
                createdAt:[]
            },
            formItems:[
                {
                    label:"用户ID",
                    prop:"userId",
                    inputComponent:"userSearch",
                    required: true,
                    disabled: true,
                },
                {
                    label:"openid",
                    prop:"openid",
                    required: true,
                },
                {
                    label:"游戏ID",
                    prop:"gameId",
                    required: true,
                },
                {
                    label:"游戏数据",
                    prop:"gameData",
                    type:"textarea",
                    rows: 5,
                    required: true,
                },
                {
                    label:"版本号",
                    prop:"version",
                    type:"number",
                    required: true,
                },
                
            ],
            viewTitle:"查看数据",
            viewVisible: false,
            viewData: {}
        }
    },
    computed: {
         
    },
    methods: {
        viewHandle(row){
            this.viewTitle = "查看数据["+row.id+"]";
            this.viewVisible = true;
            this.viewData = row.gameData || {};
        }
    }
};
</script>
<style lang="less" scoped>

</style>
