<template>


    <div class="list-view">

        <page-title>
            
        </page-title>

        <pageable
         url="/userFeedback/all" 
         :query="query"
         downloadUrl="/userFeedback/excel"
         deleteUrl="/userFeedback/del"
         editRef="editable"
         >
 
            <template v-slot:condition >
                <el-form :model="query" :inline="true" >
                    <el-form-item label="用户ID">
                        <el-input-number v-model="query.userId" placeholder="请输入用户ID" ></el-input-number>
                    </el-form-item>

                    <!-- <el-form-item label="埋点数据key">
                        <el-input v-model="query.feedback" placeholder="请输入埋点数据key" clearable></el-input>
                    </el-form-item> -->

                    <!-- <el-form-item label="埋点数据">
                        <el-input v-model="query.data" placeholder="请输入游戏ID" clearable></el-input>
                    </el-form-item> -->

                    <el-form-item label="创建时间">
                        <date-range-picker v-model="query.createdAt"  name="创建"></date-range-picker>
                    </el-form-item>
                </el-form>
            </template>

           <template v-slot:table >
                
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="userId" label="用户ID"> </el-table-column>
                <el-table-column prop="feedback" label="反馈内容"> </el-table-column>
                <el-table-column prop="createdAt" label="创建时间" min-width="220px"   > </el-table-column>
                
           </template>
        </pageable>
        
        <editable ref="editable" url="/userFeedback/update" :formItems="formItems">

        </editable>

        <el-dialog  :title="viewTitle" :visible.sync="viewVisible">
            <json-view :json="viewData"></json-view>
        </el-dialog>


    </div>
</template>
<script>


export default {
    name: 'FeedbackList',
    data() {
        return {
            query :{
                userId: undefined,
                createdAt:[]
            },
            formItems:[
                {
                    label:"用户ID",
                    prop:"userId",
                    inputComponent:"userSearch",
                    required: true,
                    disabled: true,
                },
                {
                    label:"反馈内容",
                    prop:"feedback",
                    type:"textarea",
                    rows: 5,
                    required: true,
                },
            ],
            viewTitle:"查看数据",
            viewVisible: false,
            viewData: {}
        }
    },
    computed: {
         
    },
    methods: {
        viewHandle(row){
            this.viewTitle = "查看数据["+row.id+"]";
            this.viewVisible = true;
            this.viewData = row.feedback || {};
        }
    }
};
</script>
<style lang="less" scoped>

</style>
