<template>


    <div class="list-view">

        <page-title>
            
        </page-title>

        <pageable 
            url="/signArrive/all" 
            :query="query"
            downloadUrl="/signArrive/excel"
            deleteUrl="/signArrive/del"
            editRef="editable"
         >

            <template v-slot:condition >
                <el-form :model="query" :inline="true" >
                    <el-form-item label="用户ID">
                        <el-input-number v-model="query.userId" placeholder="请输入用户ID" ></el-input-number>
                    </el-form-item>

                    <el-form-item label="openid">
                        <el-input v-model="query.openid" placeholder="请输入openid" clearable></el-input>
                    </el-form-item>


                    <el-form-item label="签到日期">
                        <date-range-picker v-model="query.signDate"  name="签到"  type="daterange" ></date-range-picker>
                    </el-form-item>

                    <el-form-item label="创建时间">
                        <date-range-picker v-model="query.createdAt"  name="创建"></date-range-picker>
                    </el-form-item>
                </el-form>
            </template>
            

           <template v-slot:table >
                
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="userId" label="用户ID"> </el-table-column>
                <el-table-column prop="openid" label="openid" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="signDate" label="签到日期"  min-width="180px" > </el-table-column>
                <el-table-column prop="week" label="星期几" show-overflow-tooltip > </el-table-column>
                <el-table-column prop="createdAt" label="创建时间" min-width="180px"   > </el-table-column>
                
           </template>
        </pageable>
        

        <editable ref="editable" url="/signArrive/update" :formItems="formItems">

        </editable>

    </div>
</template>
<script>


export default {
    name: 'SignArriveList',
    data() {
        return {
            query:{
                userId: undefined,
                openid:null,
                signDate:[],
                createdAt:[]
            },
            formItems:[
                {
                    label:"用户ID",
                    prop:"userId",
                    inputComponent:"userSearch",
                    required: true,
                },
                {
                    label:"openid",
                    prop:"openid",
                    required: true,
                },
                {
                    label:"签到日期",
                    prop:"signDate",
                    type:"date",
                    required: true,
                },
                {
                    label:"星期几",
                    prop:"week",
                    type:"number",
                    required: true,
                },
                
            ]
        }
    },
    computed: {
         
    },
    methods: {
        
    }
};
</script>
<style lang="less" scoped>

</style>
