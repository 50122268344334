<template>


    <div class="list-view">

        <page-title>
            
        </page-title>

        <pageable url="/order/all"  downloadUrl="/order/excel" :query="query">
            <template v-slot:condition >
                <el-form :model="query" :inline="true" >
                    <el-form-item label="用户ID">
                        <el-input-number v-model="query.userId" placeholder="请输入用户ID" ></el-input-number>
                    </el-form-item>

                    <el-form-item label="openid">
                        <el-input v-model="query.openid" placeholder="请输入openid" clearable></el-input>
                    </el-form-item>

                    <el-form-item label="订单号">
                        <el-input v-model="query.orderNo" placeholder="请输入订单号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="订单状态">
                        <el-select v-model="query.status" clearable>
                            <el-option v-for="(item,key) in orderStatusMap" :key="key" :value="key" :label="item">{{ item }}</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <date-range-picker v-model="query.createdAt"  name="创建"></date-range-picker>
                    </el-form-item>
                </el-form>
            </template>

           <template v-slot:table >
                
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="userId" label="用户ID"> </el-table-column>
                <el-table-column prop="openid" label="openid"> </el-table-column>
                <el-table-column prop="gameId" label="游戏ID"> </el-table-column>
                <el-table-column prop="orderNo" label="订单号"  min-width="180px" show-overflow-tooltip > </el-table-column>
                <el-table-column prop="subject" label="商品名称" show-overflow-tooltip > </el-table-column>
                <el-table-column prop="itemId" label="item编号" > </el-table-column>
                <el-table-column prop="totalPrice" label="总价"  > </el-table-column>
                <el-table-column prop="status" label="订单状态"  >
                    <template v-slot="{row}">
                        <el-tag :type="orderStatusMap[row.status].type"  v-if="orderStatusMap[row.status]">
                            {{ orderStatusMap[row.status] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createdAt" label="创建时间" min-width="180px"   > </el-table-column>
                <el-table-column prop="payTime" label="支付时间" min-width="180px"  > </el-table-column>
                
           </template>
        </pageable>
        
    </div>
</template>
<script>

import {orderStatusMap} from "@/utils/constant.js";

export default {
    name: 'OrderList',
    data() {
        return {
            orderStatusMap,
            query :{
                userId: undefined,
                openid:null,
                orderNo:null,
                status:null,
                createdAt:[]
            }
        }
    },
    computed: {
         
    },
    methods: {
        
    }
};
</script>
<style lang="less" scoped>

</style>
